import { Link } from "gatsby";
import React from "react";


export default function PartnerWithUs() {


  setTimeout(function () {
    if (document.getElementById('founders')) {
      document.getElementById('fou').style.display = "block";
      document.getElementById('founders').style.display = "block";
    }
  }, 100);

  function openTab(event) {
    var i, partner_home_tabcontent, tablinks;
    partner_home_tabcontent = document.getElementsByClassName("partner-home-tabcontent");

    console.log(partner_home_tabcontent);
    for (i = 0; i < partner_home_tabcontent.length; i++) {
      partner_home_tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    console.log(event.currentTarget.id);
    document.getElementById(event.currentTarget.id).style.display = "block";
    event.currentTarget.className += " active";
    if (event.currentTarget.id == 'founders') {

      document.getElementById('fou').style.display = "block";
    }
    if (event.currentTarget.id == 'entre') {

      document.getElementById('fou').style.display = "none";
      document.getElementById('ent').style.display = "block";

    }
    if (event.currentTarget.id == 'partners') {

      document.getElementById('fou').style.display = "none";
      document.getElementById('par').style.display = "block";

    }
    console.log(document.getElementById(event.currentTarget.id));
  }

  return (
    <>
      <section id="clients" className="section-bg">
        <div className="container">
          <div className="section-header">
            <h3>Partner with Xccelerata</h3>
          </div>
          <br />
          <div className="partner-home-tab">
            <button className="tablinks active" id="founders" onClick={openTab}>For Entrepreneurs</button>
            <button className="tablinks" id="entre" onClick={openTab}>For Partners</button>
            <button className="tablinks" id="partners" onClick={openTab}>For Investors</button>
          </div>

          <div id="fou" className="partner-home-tabcontent">
            <h3 className="partner-home-tab-heading">We invest in zealous entrepreneurs who believe in transforming the future.</h3>
            <br></br>
            <p><b>Join our global network and get access to complete business incubation support today.</b> </p>
            <br></br>
            <div className="row row-eq-height justify-content-center">

              <div className="col-lg-4 mb-4">

                <center>

                  <Link to="/entrepreneurs" target="_blank" className="page-button">
                    <b>Explore</b>
                  </Link>
                </center>
              </div>
            </div>
            {/* <Link to="/entrepreneurs">
            <button type="button" className="btn-outline-danger part-home-button">Get Started</button>
            </Link> */}
          </div>

          <div id="ent" className="partner-home-tabcontent">
            <h3 className="partner-home-tab-heading">We stimulate strategic connections for private and public institutions, corporates, civic and non-profit organizations creating a social impact</h3>
            <br></br>
            <p> <b>Join our partner ecosystem for sustainable growth and global expansion. </b></p>
            <br></br>
            <div className="row row-eq-height justify-content-center">

              <div className="col-lg-4 mb-4">

                <center>

                  <Link to="/partnerwithus" target="_blank" className="page-button">
                    <b>Explore</b>
                  </Link>
                </center>
              </div>
            </div>
            {/* <Link to="/partnerwithus">
              <button type="button" className="btn-outline-danger part-home-button" style={{ marginTop: "-10px", }}>Get Started</button>
            </Link> */}
          </div>

          <div id="par" className="partner-home-tabcontent">
            <h3 className="partner-home-tab-heading">We curate connections for accredited investors with high-growth promising start-up ventures through our innovative fund.</h3>
            <br></br>
            <p><b>Join our high-value diversified tech portfolio network and start investing today.</b> </p>
            <br></br>
            <div className="row row-eq-height justify-content-center">

              <div className="col-lg-4 mb-4">

                <center>

                  <Link to="/investwithus" target="_blank" className="page-button">
                    <b>Explore</b>
                  </Link>
                </center>
              </div>
            </div>

            {/* <Link to="/investwithus">
            <button type="button" className="btn-outline-danger part-home-button">Get Started</button>
            </Link> */}
          </div>
        </div>

      </section>
    </>
  );

}
