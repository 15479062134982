import React from "react"
import { Link } from "gatsby"

export default function JoinNetwork() {
  return (
    <>
      <section id="about" >
        <div className="container" data-aos="fade-up">
          {/*}  <div className="row row-eq-height justify-content-center">
          <div className="col-lg-12 mb-12 center-div">
            <h4>
              Do you find complying with stringent dental regulatory standards
              for sterilization -
              <br />
              <b>LABORIOUS, TIME-CONSUMING, COSTLY & OVERWHELMING </b>
              <br />
              for your staff?
              <br /> <br />
              If the answer is <b>YES</b>, then <b>STERILWIZE</b> is the
              solution that meets your need!
            </h4>
          </div>
        </div> */}
          <section id="why-us">
            <div className="row row-eq-height justify-content-center" id="why-sterilwize">
              <div>
                <header className="section-header" style={{ marginTop: "-20px", }}>
                  <h3>Join the Powerful Network of Global Founders, Mentors and Investors</h3>
                  <br />
                </header>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <i className="bi bi-graph-up icon-center" style={{ color: "red" }}></i>
                  <div className="card-body">
                    <h5 className="card-title">Ready to take your <b style={{ color: "red" }}>Startup </b> to the next level?</h5>
                    {/* <p className="card-text">
                    Effectively increase patient safety and track where an
                    instrument is at any given point, trace where and when it was
                    used and on which patient within any particular time frame.
                  </p> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="200">
                  <i className="bi bi bi-brightness-high icon-center" style={{ color: "red" }}></i>
                  {/* <img src="/assets/img/lightbulb.png" style={{height: "60px", width: "50px", marginTop: "10px", alignItems: "center", backgroundcolor: "rgb(300, 300, 300)"}} />
                 */}
                  <div className="card-body">
                    <h5 className="card-title">Passionate to <b style={{ color: "red" }}>Mentor </b> the next big idea?</h5>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="300">
                  <i className="bi bi-currency-exchange icon-center" style={{ color: "red" }}></i>
                  <div className="card-body">
                    <h5 className="card-title">Seeking to <b style={{ color: "red" }}>Invest</b> in the next unicorn?</h5>

                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <div className="row row-eq-height justify-content-center">

              <div className="col-lg-4 mb-4">
                
                <center>
                <h4 style={{alignItems: "flex-start"}}><b>XConnect Global Community</b></h4>
                  <Link to="https://xconnect.xccelerata.com/" target="_blank" className="page-button">
                    <b>Get Started</b>
                  </Link>
                </center>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}
