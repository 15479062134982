import React from "react"


export default function Hero() {
  return (
    <section className="clearfix " className="container clearfix " className="hero-img container-home" data-aos="">
      {/* Can use instead of above line
      <section id="hero" className="clearfix" className="container clearfix" className="hero-img" data-aos=""></section> */}
      {/* <img src="./assets/img/xccelerata-startup-Toronto.jpg" alt=""/> */}
      {/* <div className="container clearfix" data-aos="">
        <div
          className="hero-img"
          data-aos="zoom-out"
          data-aos-delay="200"
        >*/}
      <img style={{ width: "100%", paddingTop: "inherit", }} src="./assets/img/xccelerata-startup-Toronto.jpg" className="hero-img" alt="" />
      <div class="text-block section-header-banner1">
           <h3><b>We Invest In Your Success</b></h3>
           <h5>Be bold. Be disruptive. Accelerate with Xccelerata. A global VC with end-to-end in-house platform for start-ups, investors and experts.</h5>
           
        </div>
        <br></br>
      {/* </div>
        <div
          className="hero-info hero_banner_txt"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <h2>
          Ensure the safest<br/><br/>
          compliance
          
          </h2>
          <div>
            <Link to="/book-a-demo" className="btn-get-started scrollto">
              Book a Demo
            </Link>
            <Link to="/webinars" className="btn-get-started scrollto">
              Watch Webinar
            </Link>
          </div>
        </div>
      </div> */}

    </section>
  )
}
