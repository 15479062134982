import React from 'react'
import { Link } from 'gatsby'


export default function Programs() {
    return (
      <>
        <section id="clients" className="section-bg">


        <div className="container">
  
          <div className="section-header">
            <h3>Our Programs</h3>
          </div>
  
          <div className="row g-0 clients-wrap clearfix " data-aos="zoom-in" data-aos-delay="100">
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo" >
                <img src="/assets/img/programs/programs1.png" className="img-fluid" alt="" />
              
              </div>
            </div>
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo ">
                <img src="/assets/img/programs/programs2.png" className="img-fluid" alt="" />
              </div>
            </div>
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="/assets/img/programs/programs3.png" className="img-fluid" alt="" />
              </div>
            </div>
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="/assets/img/programs/programs4.png" className="img-fluid" alt="" />
              </div>
            </div>
  

            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="/assets/img/programs/programs5.png" className="img-fluid" data-toggle="popover" title="User Info" alt="" />
              </div>
            </div>
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="/assets/img/programs/programs6.png" className="img-fluid" alt="" />
              </div>
            </div>
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="/assets/img/programs/programs7.png" className="img-fluid" alt="" />
              </div>
            </div>
  
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img src="/assets/img/programs/program8.png" className="img-fluid" alt="" />
              </div>
            </div>
            
  
          </div>
         <br/>
          <div className="row row-eq-height justify-content-center">
            <div className="col-lg-4 mb-4">
              <center>
                <Link to="/entrepreneurs" className="page-button">
               <b> More details</b>
                </Link>
              </center>
            </div>
          </div>
  
        </div>
      </section>
      </>
    )
}
