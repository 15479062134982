import React from "react"

export default function WhoWeHelp() {
  return (
    <>
   
        <section id="our-products1">
        <div className="container" >
        <br></br>
        
          <h3 style={{textAlign: "center", fontSize: "36px", fontWeight: "500",}}>Who We Help</h3>
        
        <br></br>
        
      
          
          <br></br>
          <div className="row row-eq-height justify-content-center">
            <div className="col-lg-4 mb-4">
              <div className="card" data-aos="zoom-in" data-aos-delay="200">
                <div className="card-body">
                <img
                      src="/assets/img/Enterprise/EnterWho.png"
                      className="product" style={{height: "300px", width: "320px", position: "relative",}}
                    />
                  
                  <br></br>
                  <h5 className="card-title">
                  Enterprise
                  </h5>
                </div>
                
              </div>
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card" data-aos="zoom-in" data-aos-delay="300">
                <div className="card-body">
                <img
                      src="/assets/img/Growth/38-150-01.png"
                      className="product" style={{height: "300px", width: "320px", position: "relative",}}
                    />
                  
                  <br></br>
                  <h5 className="card-title">
                  Growth
                  </h5>
                </div>
                
              </div>
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card" data-aos="zoom-in" data-aos-delay="100">
                <div className="card-body">
                <img
                      src="/assets/img/Startup/5013337.png"
                      className="product" style={{height: "300px", width: "320px", position: "relative",}}
                    />
                  
                  <br></br>
                  <h5 className="card-title">
                    Start-Up
                  </h5>
                </div>
              </div>
            </div>

           </div>

            </div>
          
        </section>
    </>
    
  )
}
