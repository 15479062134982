import React from "react"
import Header from '../components/Header'
import Navigation from '../components/Navigation'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import Includes from '../components/Includes'
import JoinNetwork from "./JoinNetwork"
import WhoWeHelp from "./Who We Help"
import PartnerWithUs from "./PartnerWithUs"
import Programs from "./Programs"
import Team from "./Team"




export default function Layout() {
  return (
    <>
      <Header />
      <Navigation />
      <Hero />   
      <br></br>
      <main id="main">
        <JoinNetwork/>
        <br></br>
        <Programs/>
        <WhoWeHelp/>
        <br></br>
        <br></br>
        <br></br>
        <PartnerWithUs/>
        {/* <Team/> */}
        </main>
        <Includes/>
        <Footer/>
    </>
  )
}