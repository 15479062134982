import React from "react"

export default function Team() {
  return (
    <>
    <section id="team">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3>Team</h3>
          <p></p>
        </div>

        <div className="row">
          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <div className="member">
              <img src="/assets/img/team/ushi.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Ushwinder Bhatia</h4>
                  <span>Chief Executive Officer</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <div className="member">
              <img src="/assets/img/team/drraj.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Dr. Raj</h4>
                  <span>Chief Visionary Officer</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="300"
          >
            <div className="member">
              <img src="/assets/img/team/amulya.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Amulya Tandon</h4>
                  <span>Chief Operating Officer</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="400"
          >
            <div className="member">
              <img src="/assets/img/team/upinder.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Upinder Bhatia</h4>
                  <span>Chief Technology Officer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <div className="member">
              <img src="/assets/img/team/drrobert.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Dr. Robert Carroll</h4>
                  <span>Chief Dental Advisor</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <div className="member">
              <img src="/assets/img/team/drclaudio.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Dr. Claudio Tocchio</h4>
                  <span>Chief Hospital Advisor</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-out"
            data-aos-delay="300"
          >
            <div className="member">
              <img src="/assets/img/team/marklin.png" className="img-fluid" alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Mark Lin</h4>
                  <span>Advisory board</span>

                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-3"
            data-aos="zoom-out"
            data-aos-delay="300"
          >
            <div className="member">
              <img src="/assets/img/team/susan.png" className="img-fluid" style={{ borderRadius: "50%", }} alt="" />
              <div className="member-info">
                <div className="member-info-content">
                  <h4>Susan McGuire </h4>
                  <span>VP, Sales and Business Development</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row row-eq-height justify-content-center">
            <div className="col-lg-4 mb-4">
              <center>
                <Link to="/team1" className="btn page-button">
                  More About Our Team
                </Link>
              </center>
            </div>
          </div> */}
    </section>
    </>
  )
}
